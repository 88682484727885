$(document).ready(function() {
    var elemHamb = $('#hamburger'),
        elemHambu = $('#hamburger_2'),
        elemNavi = $('#nf');
    elemHamb.click(function() {
        elemNavi.toggle();
    });
    elemHambu.click(function() {
        elemNavi.toggle();
    });
    $(".gallery").lightGallery(); 
});